.navbar-item__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-item__text {
  color: black;
  font-size: 16px;
  font-weight: 500;
  font-family: "Aptos", sans-serif;
  border-bottom: 1px solid transparent;
}

.navbar-item__wrapper:hover {
  cursor: pointer;
  border-bottom: 1px solid black;
}

.white-text {
  color: #ffffff;
  border-bottom: 1px solid transparent;
}

.white-text:hover {
  border-bottom: 1px solid #ffffff;
}
