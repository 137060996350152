.section-header__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.section-header__title {
  color: #003a83;
  text-align: center;
  font-family: "Aptos", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 70px;
}

.section-header__text {
  color: #000;
  text-align: center;
  font-family: "Aptos", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px;
  padding-left: 100px;
  padding-right: 100px;
}

.section-header__half-width {
  width: 50%;
}

@media screen and (max-width: 795px) {
  .section-header__title {
    margin-bottom: 25px;
  }
}
