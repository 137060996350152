.organitzacio__container {
  width: calc(100% - 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
  background-image: url("../../../assets/vectorRotat.png");
  background-size: cover;
  margin-top: 70px;
  padding-left: 5%;
  padding-right: 5%;
}

.organitzacio__item-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 795px) {
  .organitzacio__item-container {
    width: 100%;
  }
}
