.dates-item__container {
  width: calc(100% - 2rem);
  border-radius: 5px;
  border: 0.5px solid #c0baba;
  background: #fff;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  height: calc(100px - 1rem);
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  position: relative;
}

.dates-item__text p {
  color: #000;
  font-family: "Aptos", sans-serif;
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  line-height: 27px; /* 150% */
  letter-spacing: 0.18px;
  margin: 0;
}
.dates-item__date-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.dates-item__date-wrapper p {
  margin: 0;
}

.dates-item__date {
  color: #000;
  font-family: "Aptos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  line-height: 27px;
}

.calendar-icon:hover {
  cursor: pointer;
}

.calendar-wrapper {
  position: absolute; /* Posició absoluta */
  top: -10; /* Ajusta la posició superior segons la teva necessitat */
  right: 0; /* Ajusta la posició esquerra segons la teva necessitat */
  z-index: 999;
}

.react-datepicker__day {
  cursor: default !important; /* Anula l'estil de cursor pointer */
}

.disable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999; /* Assegura que està per sobre del calendari */
  cursor: not-allowed; /* Canvia el cursor per indicar que no es pot interactuar */
}

.overlay-container {
  position: relative;
}

@media screen and (max-width: 795px) {
  .dates-item__date {
    font-size: 14px;
  }
  .dates-item__text p {
    font-size: 14px;
    text-align: left;
  }
  .dates-item__text {
    align-self: flex-start;
  }
  .dates-item__container {
    height: auto;
    flex-direction: column;
    border: none;
    border-bottom: 0.5px solid #c0baba;
    padding: 1rem;
  }
  .dates-item__date-wrapper {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
  }
}
