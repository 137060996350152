.footer__container {
  height: 310px;
  background-color: #003a83;
  width: calc(100% - 200px);
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
}

.footer__top-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  border-bottom: 1px solid white;
}

.footer__top-wrapper__left-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer__top-wrapper__left-wrapper span {
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__top-wrapper__right-wrapper {
  display: flex;
  gap: 30px;
  align-items: center;
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__bottom-wrapper {
  padding-top: 50px;
}

.footer__bottom-wrapper {
  display: flex;
  justify-content: space-between;
}

.footer__bottom-wrapper p {
  margin: 0;
  vertical-align: middle;
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer__bottom-wrapper__right-wrapper {
  display: flex;
  gap: 20px;
}

.footer__icon:hover {
  cursor: pointer;
}

.footer__logo {
  height: 80px;
}

.footer__logo:hover {
  cursor: pointer;
}

@media screen and (max-width: 795px) {
  .footer__container {
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
  }
  .footer__top-wrapper__left-wrapper {
    align-self: center;
  }
  .footer__top-wrapper__right-wrapper {
    flex-direction: column;
    gap: 15px;
  }
}
