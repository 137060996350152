.contacte-item__container {
  width: 485px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #003a83;
  background: #fff;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contacte-item__container:hover {
  cursor: pointer;
}
.contacte-item__container:last-child {
  margin-bottom: 0;
}
.contacte-item__left-wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.contacte-item__icon-container {
  background-color: #003a83;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contacte-item__right-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
}

.contacte-item__title {
  color: #003a83;
  font-family: "Aptos", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contacte-item__subtitle {
  color: #3d3d3d;
  font-family: "Aptos", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px; /* 150% */
}

@media screen and (max-width: 795px) {
  .contacte-item__container {
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .contacte-item__icon-container {
    height: 40px;
    width: 40px;
  }

  .contacte-item__title {
    font-size: 16px;
  }
  .contacte-item__subtitle {
    font-size: 12px;
    display: block;
    overflow-wrap: break-word;
    line-height: normal;
  }
  .contacte-item__right-wrapper {
    max-width: 55%;
    padding-left: 5px;
  }
  .contacte-item__left-wrapper {
    padding: 15px;
  }
}
