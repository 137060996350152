.navbar__container {
  width: 100%;
  height: calc(100px - 20px);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  transition: background-color 0.5s ease;
}
.navbar__logo {
  height: 90%;
  align-self: center;
}
.navbar__image-wrapper {
  display: flex;
  margin-left: 40px;
}

.navbar__container-scrolled {
  background-color: #003a83;
}

.navbar__items-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  padding-right: 50px;
}

.navbar__logo:hover {
  cursor: pointer;
}

@media screen and (max-width: 795px) {
  .navbar__logo {
    height: 50%;
  }
  .navbar__image-wrapper {
    margin-left: 20px;
  }
  .navbar__dropdown-menu {
    position: fixed;
    top: 80px;
    right: 0;
    background: var(--congress-blue);
    width: 40%;
    height: 30%;
    border-radius: 0 0 10px 10px;
  }
  .navbar__items-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100% - 40px);
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    width: calc(100vw);
  }
}
