.overlay {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7); /* Fons semitransparent */
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-container {
  width: 350px;
  height: 500px;
  border-radius: 15px;
  background-color: antiquewhite;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Aptos", sans-serif;
}

.call-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 22px;
  height: 60%;
  font-weight: 700;
}

.call-wrapper span:hover {
  cursor: pointer;
}

.call-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.call-close:hover {
  cursor: pointer;
}

@media screen and (max-width: 795px) {
  .call-container {
    width: 75%;
    height: 60%;
  }

  .call-wrapper {
    font-size: 18px;
  }
}
