.organitzacio-item__container {
  width: 400px;
  display: flex;
  border-radius: 30px;
  background: #fff;
  box-shadow: 4px 10px 30px 0px rgba(0, 0, 0, 0.06);
  padding-right: 30px;
  position: relative;
}

.organitzacio-item__container:hover {
  cursor: pointer;
  scale: 0.999;
}

.organitzacio-item__icon-wrapper {
  width: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.organitzacio-item__title {
  color: #1f1f1f;
  font-family: "Aptos", sans-serif;
  font-size: 19.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.organitzacio-item__arrow {
  position: absolute;
  bottom: calc(50% - 10px);
  right: 20px;
}

.organitzacio-item__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-bottom {
  margin-bottom: 30px;
}

@media screen and (max-width: 795px) {
  .organitzacio-item__container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
  }

  .organitzacio-item__icon-wrapper {
    padding: 15px;
  }
}
