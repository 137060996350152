.eixos__container {
  padding-top: 2rem;
  margin-bottom: 50px;
}

.eixos__section {
  color: #003a83;
  text-align: center;
  font-family: "Aptos";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.eixos__section-text {
  color: #000;
  text-align: center;
  font-family: "Aptos", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 47px; /* 117.5% */
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 2rem;
}

.eixos__items-wrapper-wrapper {
  display: flex;
  justify-content: center;
}

.eixos__items-wrapper {
  width: 32%;
  display: flex;
  flex-direction: column;
}

.eixos-display__info ul {
  margin: 0;
}

.eixos-display__info li {
  margin-bottom: 10px;
}

.eixos-display__info li:last-child {
  margin-bottom: 10px;
}
.eixos-display__info li:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 795px) {
  .eixos__items-wrapper-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .eixos__items-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 70%;
  }
  .eixos__section-text {
    font-size: 26px;
  }
  .eixos__section {
    font-size: 18px;
  }
}
