body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Aptos", sans-serif;
}

:root {
  --congress-blue: #003a83;
}

a {
  text-decoration: none;
}
