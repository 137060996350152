.normes-item__number {
  width: 90px;
  height: 90px;
  background-color: #003a83;
  filter: drop-shadow(4px 10px 30px rgba(67, 97, 238, 0.4));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normes-item__number p {
  color: #fff;
  font-family: "Aptos", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.normes-item__title {
  color: #2b2b2b;
  font-family: "Aptos", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 30px;
}

.normes-item__text {
  color: #808080;
  text-align: justify;
  font-family: "Aptos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 144.444% */
  margin: 0;
  margin-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 80px);
  overflow-wrap: break-word;
}

.normes-item__blur {
  width: 100%;
  height: 55px;
  backdrop-filter: blur(10px); /* Aplica un desenfocament al fons */
  position: absolute;
  bottom: 0;
  border-radius: 30px;
}

.normes-item__arrow {
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 2;
}

.normes-item__arrow:hover {
  cursor: pointer;
}

.normes-item__white-block {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #fff;
}

.normes-item__text-padding-bottom {
  padding-bottom: 25px;
}

@media screen and (max-width: 795px) {
  .normes-item__number {
    width: 50px;
    height: 50px;
  }
  .normes-item__number p {
    font-size: 24px;
  }
  .normes-item__title {
    font-size: 18px;
  }
  .normes-item__text {
    font-size: 12px;
    text-align: left;
    margin-top: 10px;
  }
  .normes-item__text-padding-bottom {
    padding-bottom: 15px;
  }
}
