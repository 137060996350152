.vertical-contact__container {
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  position: absolute;
  right: 35px;
  top: calc(50% - 75px);
}

.vertical-contact__item:hover {
  cursor: pointer;
  scale: 1.1;
}
