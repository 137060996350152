.contacte__container {
  padding: 100px;
  padding-top: 70px;
  background-image: url("../../../assets//Vector\ digitalització.jpg");
  background-size: cover;
}

.contacte__left-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 795px) {
  .contacte__container {
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contacte__left-wrapper {
    width: 80%;
  }
}
