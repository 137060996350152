.normes__container {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 90px;
  padding-bottom: 90px;
  padding-left: 90px;
  padding-right: 90px;
}

.normes__items-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

@media screen and (max-width: 795px) {
  .normes__container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
