.navbarButtonContainer {
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin: 0;
  transition: scale 0.2s;
}

.navbarButtonContainer:active,
.navbarButtonContainer:focus {
  scale: 0.95;
}
.navbarButtonContainer:hover {
  cursor: pointer;
}

.blue {
  background-color: #003a83;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.white {
  background-color: #ffffff;
  color: #003a83;
  border: 1px solid transparent;
}

.navbarButtonContainer p {
  font-family: "Aptos", sans-serif;
  font-size: 14px;
  margin: 0;
}

.home-white {
  border: 1px solid #003a83;
}

.scipedia-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 795px) {
  .navbarButtonContainer {
    min-width: 50%;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
