.eixos-display__title {
  color: #2b2b2b;
  font-family: "Aptos", sans-serif;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 0.5px solid black;
  border-bottom-style: dotted;
  font-size: 18px;
  font-style: bold;
  font-weight: 700;
  line-height: 27px; /* 150% */
  letter-spacing: 0.18px;
  margin: 0;
}

.eixos-display__title:hover {
  cursor: pointer;
  background-color: var(--congress-blue);
  color: #ffffff;
}
.eixos-display__info {
  color: #2b2b2b;
  font-family: "Aptos", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
}

.display-info {
  max-height: 1000px;
  overflow: visible;
}

.border-right {
  border-right: 1px solid #2b2b2b;
}
.border-left {
  border-left: 1px solid #2b2b2b;
}
.border-bottom {
  border-bottom: 1px solid #2b2b2b;
}
.border-top {
  border-top: 1px solid #2b2b2b;
}

@media screen and (max-width: 795px) {
  .eixos-display__title {
    font-size: 16px;
  }
  .eixos-display__info {
    font-size: 12px;
    line-height: 20px;
  }
  .eixos-display__info ul {
    padding-right: 1rem;
  }
}
