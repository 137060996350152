.dates__pater {
  width: calc(100% - 12%);
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.dates__container {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  box-shadow: 4px 10px 30px 0px rgba(0, 58, 131, 0.1);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  align-items: center;
}

.dates__items-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-around;
  border-radius: 10px;
  border: 2px solid #003a83;
  background: #fff;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
  width: 70%;
}

@media screen and (max-width: 795px) {
  .dates__items-container {
    border: none;
    width: 100%;
    padding-top: 0;
  }
  .dates__container {
    margin-top: 100px;
  }
}
